<template>
<div class="transport-contract-form">
    <h4>Update Contract: {{ companyname_header }}</h4>
    <div class="card form-view" style="margin-bottom: 1rem">
        <div class="p-field contract-input-bar">
            <label>Contract List:</label>
            <form @submit.prevent="contractform_type == 'add' ? addTransportationContract() : updateTransportationContract()">
                <InputText id="contract_name" v-model="transport_contract_form.contract_name" type="text" placeholder="Contract name" :class="{'p-invalid':v_contractform$.contract_name.$error}"/>
                <Button label="Save" v-if="contractform_type == 'add'" type="submit" class="p-button-sm p-button-success" icon="pi pi-save" />
                <span v-if="contractform_type == 'update'">
                    <Button icon="pi pi-check" type="submit" class="small-datatable-button p-button-success p-button-rounded p-mr-1" />
                    <Button icon="pi pi-times" class="small-datatable-button p-button-secondary p-button-rounded" @click="cancelContractUpdate()" />
                </span>
            </form>
        </div>

        <DataTable
            :value="transportationContractList"
            responsiveLayout="scroll"
            scrollHeight="245px"
            selectionMode="single"
            v-model:selection="selectedTransportationContract"
            @rowSelect="onTransportContractRowSelect"
            dataKey="id"
        >
            <Column field="id" header="ID" :style="{'min-width':'60px'}"></Column>
            <Column field="contract_name" header="Contract name"></Column>

            <Column :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="editTransportationContract(slotProps.data)"/>
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteTransportationContract(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>

    <div class="card form-view" style="margin-bottom: 1rem">
        <div class="p-field contract-input-bar">
            <label>Contract Validity:</label>
            <form @submit.prevent="contractperiodform_type == 'add' ? addTransportationContractPeriod() : updateTransportationContractPeriod()">
                <div class="p-fluid p-grid p-field">
                    <label class="p-col" for="valid_from">From:</label>
                    <div class="p-col">
                        <Calendar id="navigators" style="width: 18rem; height: 2.2rem" v-model="trans_contractperiod_form.valid_from" :manualInput="false" :monthNavigator="true" :yearNavigator="true" yearRange="2000:2030" dateFormat="dd-M-yy" :showIcon="true" :class="{'p-invalid':v_contractperiod$.valid_from.$error || isDateValid.isInvalid}"/>
                        <small v-if="v_contractperiod$.valid_from.$error" class="p-error">{{v_contractperiod$.valid_from.required.$message}}</small>
                        <small v-if="isDateValid.isInvalid" class="p-error">{{isDateValid.message}}</small>
                    </div>
                    <label class="p-col" for="valid_to" >To:</label>
                    <div class="p-col">
                        <Calendar id="navigators" style="width: 18rem; height: 2.2rem" v-model="trans_contractperiod_form.valid_to" :manualInput="false" :monthNavigator="true" :yearNavigator="true" yearRange="2000:2030" dateFormat="dd-M-yy" :showIcon="true" :class="{'p-invalid':v_contractperiod$.valid_to.$error || isDateValid.isInvalid}"/>
                        <small v-if="v_contractperiod$.valid_to.$error" class="p-error">{{v_contractperiod$.valid_to.required.$message}}</small>
                        <small v-if="isDateValid.isInvalid" class="p-error">{{isDateValid.message}}</small>
                    </div>

                    <div class="p-col">
                        <Button label="Save" v-if="contractperiodform_type == 'add'" type="submit" style="width: 5rem" class="p-button-sm p-button-success" icon="pi pi-save" :disabled="selectedTransportationContract.id == 0"/>
                        <span v-if="contractperiodform_type == 'update'">
                            <Button icon="pi pi-check" type="submit" class="small-datatable-button p-button-success p-button-rounded p-mr-1" />
                            <Button icon="pi pi-times" class="small-datatable-button p-button-secondary p-button-rounded" @click="cancelContractPeriodUpdate()" />
                        </span>
                    </div>
                </div>
            </form>
        </div>
        <div class="p-grid p-field">
            <div class="p-col">
                <label class="p-mr-5">Contract:</label>
                <span style="font-weight: 500">{{selectedTransportationContract.contract_name}}</span>
            </div>
        </div>
        <DataTable
            id="Contract-Period"
            :value="transContractPeriodList"
            responsiveLayout="scroll"
            scrollHeight="245px"
            selectionMode="single"
            v-model:selection="selectedTransContractPeriod"
            @rowSelect="onTransContractPeriodRowSelect"
            dataKey="id"
        >
            <Column field="transport_contract_id" header="Contract ID" :style="{'min-width':'30px'}"></Column>
            <Column field="valid_from" header="From" :style="{'min-width':'60px'}"></Column>
            <Column field="valid_to" header="To" :style="{'min-width':'60px'}"></Column>

            <Column :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="editTransContractPeriod(slotProps.data)"/>
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteTransContractPeriod(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>

    <div class="card form-view" style="margin-bottom: 1rem">
        <div class="p-field">
            <label class="p-mr-4 p-mb-3">Vehicle Rate:</label>
            <span style="font-weight: 500">
                <!-- {{ contractRateTitle }} -->
            </span>
            <form @submit.prevent="vehicle_rate_form_type == 'add' ? addVehicleRate() : updateVehicleRate()">
                <div class="p-fluid p-grid p-field">
                    <div class="p-col-fixed">
                        <label for="desc">Description:</label>
                    </div>
                    <div class="p-col">
                        <InputText id="desc" v-model="vehicle_rate_form.desc" type="text" :class="{'p-invalid':v_vehiclerate$.desc.$error}"/>
                    </div>                    
                </div>
                <div class="p-fluid p-grid p-field">
                    <div class="p-col">
                        <label for="car">Car:</label>
                        <InputNumber v-model="vehicle_rate_form.car_rate" id="car_rate" :class="{'p-invalid':isVehicleRateNull}" />
                    </div>
                    <div class="p-col">
                        <label for="van">Van:</label>
                        <InputNumber v-model="vehicle_rate_form.van_rate" id="van_rate" :class="{'p-invalid':isVehicleRateNull}" />
                    </div>
                    <div class="p-col">
                        <label for="25seats">25 seats:</label>
                        <InputNumber v-model="vehicle_rate_form.v25s_rate" id="25seats_rate" :class="{'p-invalid':isVehicleRateNull}" />
                    </div>
                    <div class="p-col">
                        <label for="35seats">35 seats:</label>
                        <InputNumber v-model="vehicle_rate_form.v35s_rate" id="35seats_rate" :class="{'p-invalid':isVehicleRateNull}" />
                    </div>
                    <div class="p-col">
                        <label for="45seats">45 seats:</label>
                        <InputNumber v-model="vehicle_rate_form.v45s_rate" id="45seats_rate" :class="{'p-invalid':isVehicleRateNull}" />
                    </div>
                    <div class="p-col-fixed" style="text-align: right; align-self: flex-end;">
                        <Button label="Save" v-if="vehicle_rate_form_type == 'add'" type="submit" style="width: 5rem" class="p-button-sm p-button-success" icon="pi pi-save" :disabled="!vehicleRateButton" />
                        <span v-if="vehicle_rate_form_type == 'update'">
                            <Button icon="pi pi-check" type="submit" class="small-datatable-button p-button-success p-button-rounded p-mr-1" />
                            <Button icon="pi pi-times" class="small-datatable-button p-button-secondary p-button-rounded" @click="cancelVehicleRateUpdate()" />
                        </span>
                    </div>
                </div>
            </form>

            <DataTable
                id="Vehicle-Rate"
                :value="vehicleRateList"
                responsiveLayout="scroll"
                scrollHeight="245px"
                selectionMode="single"
                v-model:selection="selectedVehicleRate"
                @rowSelect="onVehicleRateRowSelect"
                dataKey="id"
            >
                <Column field="desc" header="Description" :style="{'min-width':'100px'}"></Column>
                <Column field="car_rate" header="Car" :style="{'min-width':'60px'}"></Column>
                <Column field="van_rate" header="Van" :style="{'min-width':'60px'}"></Column>
                <Column field="v25s_rate" header="25 Seats" :style="{'min-width':'75px'}"></Column>
                <Column field="v35s_rate" header="35 Seats" :style="{'min-width':'75px'}"></Column>
                <Column field="v45s_rate" header="45 Seats" :style="{'min-width':'75px'}"></Column>

                <Column :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                    <template #body="slotProps">
                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="editVehicleRate(slotProps.data)"/>
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteVehicleRate(slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
    <ConfirmDialog></ConfirmDialog>
</div>
</template>

<script>
import { ref, reactive, computed, onMounted } from 'vue'
import { useConfirm } from "primevue/useconfirm";
import { useRoute } from 'vue-router';
import { useToast } from "primevue/usetoast";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import dayjs from 'dayjs';

import TransportationService from '../service/TransportationService'

export default {
    setup() {
        const confirm = useConfirm()
        const route = useRoute()
        const toast = useToast()

        const transportation_id = route.query.transportationid
        let companyname_header = ref('')

        const transService = ref(new TransportationService())

        onMounted(() => {
            getTransportationCompanyName()
            getTransportationContract()
        })

        const getTransportationCompanyName = () => {
            transService.value.getTransportationCompanyName(transportation_id).then(data =>  {
                if(data.errorResponse)  {
                    companyname_header.value = data.errorResponse
                } else {
                    companyname_header.value = data
                }
            })
        }

        //==============CONTRACT LIST==============
        let transportationContractList = ref([])
        let selectedTransportationContract = ref({id: 0, contract_name: ''})
        let transport_contract_form = reactive({})
        let contractform_type = ref('add')

        const initContractForm = () => {
            const contractForm = {
                contract_name: '',
                transportation_id: 0
            }

            Object.assign(transport_contract_form, contractForm)
        }

        const getTransportationContract = () => {
            transService.value.getContract(transportation_id).then((data) => {
                transportationContractList.value = data
            })
        }

        const onTransportContractRowSelect = () => {
            cancelContractUpdate()
            getTransportationContractPeriod(selectedTransportationContract.value.id)

            cancelContractPeriodUpdate()
            selectedTransContractPeriod.value = {}

            cancelVehicleRateUpdate()
            vehicleRateButton.value = false
            vehicleRateList.value.length = 0
        }

        const addTransportationContract = () => {
            if(validateContractForm()) {
                transport_contract_form.transportation_id = transportation_id
                transService.value.addTransportationContract(transport_contract_form).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            toast.add({severity:'warn', summary: 'Add Contract Error', detail: data.message, life: 5000}); 
                        } else {
                            getTransportationContract()
                            initContractForm()
                            v_contractform$.value.$reset()
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Add Contract Error ' + data.status, detail: data.errorResponse, life: 5000}); 
                    }
                })
            }
        }

        const editTransportationContract = (data) => {
            contractform_type.value = 'update'
            selectedTransportationContract.value = data
            transport_contract_form.contract_name = data.contract_name
        }

        const updateTransportationContract = () => {
            if(validateContractForm()) {
                const contract_id = selectedTransportationContract.value.id
                if(selectedTransportationContract.value.contract_name != transport_contract_form.contract_name) {
                    transService.value.updateTransportationContract(contract_id, {contract_name: transport_contract_form.contract_name, transportation_id: transportation_id}).then(data => {
                        if(!data.errorResponse) {
                            if(data.status == 400) {
                                toast.add({severity:'warn', summary: 'Update Contract Error ', detail: data.message, life: 5000}); 
                            } else {
                                getTransportationContract()
                                initContractForm()
                                v_contractform$.value.$reset()
                                contractform_type.value = 'add'
                            }
                        } else {
                            toast.add({severity:'warn', summary: 'Update Contract Error ' + data.status, detail: data.errorResponse, life: 5000}); 
                        }
                    })
                }
            }
        }

        const confirmDeleteTransportationContract = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.contract_name +'" from this company?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const contract_id = data.id
                    transService.value.deleteTransportationContract(contract_id).then((data) => {
                        if(!data.errorResponse){
                            getTransportationContract()
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});        
                        }
                    })
                }
            });
        }

        const cancelContractUpdate = () =>{
            contractform_type.value = 'add'
            v_contractform$.value.$reset()
            initContractForm()
        }

        //Form Validation
        const transportcontractform_rules = computed(() => {
            return {
                contract_name: { required },
            }            
        })
        const v_contractform$ = useVuelidate(transportcontractform_rules, transport_contract_form)
        const validateContractForm = () => {
            v_contractform$.value.$validate();
            if(!v_contractform$.value.$error){
                return true
            } else {
                return false
            }
        }

        //==============CONTRACT PERIOD==============
        let transContractPeriodList = ref([])
        let selectedTransContractPeriod= ref({})        
        let contractperiodform_type = ref('add')
        let trans_contractperiod_form = reactive({})
        let isDateValid = ref({isInvalid: false, message: ''})
        let contractRateTitle = ref()

        const initContractPeriodForm = () => {
            const contractPeriodForm = {
                valid_from: '',
                valid_to: '',
                transport_contract_id: 0
            }            

            Object.assign(trans_contractperiod_form, contractPeriodForm)
        }

        const onTransContractPeriodRowSelect = () => {
            cancelContractPeriodUpdate()

            contractRateTitle.value = selectedTransportationContract.value.contract_name + ' [' + selectedTransContractPeriod.value.valid_from + ' - ' + selectedTransContractPeriod.value.valid_to + ']'
            getTransportationRate()
            vehicleRateButton.value = true
            // getMenuPrice()
            // selectedMenuPrice.value = {}
            // isSaveDishButton.value = false
            // menuPriceButton.value = true
            // menuDishes.dish_desc = ''
            // contract_policy.value = []
        }

        function getTransportationContractPeriod(contract_id) {
            transService.value.getTransportationContractPeriod(contract_id).then(data => {
                if(!data.errorResponse) {
                    transContractPeriodList.value = data
                } else {
                    toast.add({severity:'warn', summary: 'Getting Contract Period Error ' + data.status, detail: data.errorResponse, life: 5000});
                }
            })
        }

        const addTransportationContractPeriod = () => {
            if(selectedTransportationContract.value.id != 0) {
                if(validateForm() && validateContractPeriodDate()) {
                    const validFrom = dayjs(trans_contractperiod_form.valid_from).format('DD-MMM-YYYY')
                    const validTo =  dayjs(trans_contractperiod_form.valid_to).format('DD-MMM-YYYY')

                    trans_contractperiod_form.valid_from = validFrom
                    trans_contractperiod_form.valid_to = validTo
                    trans_contractperiod_form.transport_contract_id = selectedTransportationContract.value.id

                    transService.value.addTransportContractPeriod(trans_contractperiod_form).then(data => {
                        if(!data.errorResponse) {
                            initContractPeriodForm()
                            getTransportationContractPeriod(selectedTransportationContract.value.id)
                            v_contractperiod$.value.$reset() //Reset validations
                        } else {
                            toast.add({severity:'warn', summary: 'Add Contract Period Error', detail: data.errorResponse, life: 6000}); 
                        }
                    })
                }
            }
        }

        const editTransContractPeriod = (data) => {
            contractperiodform_type.value = 'update'
            selectedTransContractPeriod.value = data

            trans_contractperiod_form.valid_from = data.valid_from
            trans_contractperiod_form.valid_to = data.valid_to
        }

        const updateTransportationContractPeriod = () => {
            if(selectedTransportationContract.value.id != 0) {
                if(validateForm() && validateContractPeriodDate()) {
                    const validFrom = dayjs(trans_contractperiod_form.valid_from).format('DD-MMM-YYYY')
                    const validTo =  dayjs(trans_contractperiod_form.valid_to).format('DD-MMM-YYYY')

                    const id = selectedTransContractPeriod.value.id
                    trans_contractperiod_form.valid_from = validFrom
                    trans_contractperiod_form.valid_to = validTo
                    
                    transService.value.updateTransportContractPeriod(id, trans_contractperiod_form).then(data => {
                        if(!data.errorResponse) {
                            initContractPeriodForm()
                            getTransportationContractPeriod(selectedTransportationContract.value.id)
                            contractperiodform_type.value = 'add'
                            v_contractperiod$.value.$reset() //Reset validations
                        } else {
                            toast.add({severity:'warn', summary: 'Update Contract Period Error '+data.status, detail: data.errorResponse, life: 6000}); 
                        }
                    })   
                }
            }
        }

        const confirmDeleteTransContractPeriod = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete this transportation contract period?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const id = data.id
                    transService.value.deleteTransportContractPeriod(id).then((data) => {
                        if(!data.errorResponse){
                            getTransportationContractPeriod(selectedTransportationContract.value.id)
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error '+data.status, detail: data.errorResponse, life: 6000});        
                        }
                    })
                }
            });
        }

        const cancelContractPeriodUpdate = () =>{
            contractperiodform_type.value = 'add'
            initContractPeriodForm()
            v_contractperiod$.value.$reset()
            isDateValid.value = {isInvalid: false, message: ''}
        }

        const validateContractPeriodDate = () => {
            const validfrom = new Date(trans_contractperiod_form.valid_from)
            const validto = new Date(trans_contractperiod_form.valid_to)
            if(validfrom > validto) {
                isDateValid.value = {isInvalid: true, message: 'Selected date is invalid.'}
                return false
            } else {
                isDateValid.value = {isInvalid: false, message: ''}
                return true
            }
        }

        //Form Validations
        const transcontractperiod_rules = computed(() => {
            return {
                valid_from: { required },
                valid_to: { required },
            }            
        })
        const v_contractperiod$ = useVuelidate(transcontractperiod_rules, trans_contractperiod_form)
        const validateForm = () => {
            v_contractperiod$.value.$validate();
            if(!v_contractperiod$.value.$error){
                return true
            } else {
                return false
            }
        }

        //==============VEHICLE RATE==============
        let vehicleRateList = ref([])
        let selectedVehicleRate= ref({})        
        let vehicle_rate_form_type = ref('add')
        let vehicle_rate_form = reactive({})
        let isVehicleRateNull = ref(false)
        let vehicleRateButton = ref(false)

        const initVehicleRateForm = () => {
            const vehicleRateForm = {
                id: 0,
                desc: '',
                car_rate: null,
                van_rate: null,
                v25s_rate: null,
                v35s_rate: null,
                v45s_rate: null,                
                transcontract_period_id: 0
            }            

            Object.assign(vehicle_rate_form, vehicleRateForm)
        }

        const onVehicleRateRowSelect = () => {

        }

        function getTransportationRate() {
            const periodid = selectedTransContractPeriod.value.id
            
            transService.value.getTransportationRate(periodid).then((data) => {
                if(data.length > 0) {
                    vehicleRateList.value = data
                } else {
                    vehicleRateList.value.length = 0
                }
            })
        }

        const addVehicleRate = () => {
            if(selectedTransContractPeriod.value.id != 0) {
                if(validateVehicleRateForm() && validateVehicleRate()) {
                    vehicle_rate_form.transcontract_period_id = selectedTransContractPeriod.value.id

                    transService.value.addTransportationRate(vehicle_rate_form).then(data => {
                        if(!data.errorResponse) {
                            getTransportationRate()
                            initVehicleRateForm()
                            v_vehiclerate$.value.$reset() //Reset validations
                            isVehicleRateNull.value = false
                        } else {
                            toast.add({severity:'warn', summary: 'Add Vehicle Ratae Error '+data.status, detail: data.errorResponse, life: 6000}); 
                        }
                    })
                }
            }
        }

        const editVehicleRate = (data) => {
            vehicle_rate_form_type.value = 'update'

            selectedVehicleRate.value = data //Select current edit row
            vehicle_rate_form.id = data.id
            vehicle_rate_form.desc = data.desc
            vehicle_rate_form.car_rate = data.car_rate
            vehicle_rate_form.van_rate = data.van_rate
            vehicle_rate_form.v25s_rate = data.v25s_rate
            vehicle_rate_form.v35s_rate = data.v35s_rate
            vehicle_rate_form.v45s_rate = data.v45s_rate
        }

        const updateVehicleRate = () => {
            if(selectedTransContractPeriod.value.id != 0) {
                if(validateVehicleRateForm() && validateVehicleRate()) {
                    delete vehicle_rate_form.transcontract_period_id //DELETE THIS KEY AS IT'S NOT NECESSARY TO UPDATE

                    transService.value.updateTransportationRate(vehicle_rate_form.id, vehicle_rate_form).then(data => {
                        if(!data.errorResponse) {
                            getTransportationRate()
                            initVehicleRateForm()
                            vehicle_rate_form_type.value = 'add'
                            v_vehiclerate$.value.$reset() //Reset validations
                            isVehicleRateNull.value = false
                        } else {
                            toast.add({severity:'warn', summary: 'Update Vehicle Rate Error '+data.status, detail: data.errorResponse, life: 6000}); 
                        }
                    })
                }
            }
        }

        const confirmDeleteVehicleRate = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete this vehicle rate?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const id = data.id
                    transService.value.deleteTransportationRate(id).then((data) => {
                        if(!data.errorResponse){
                            getTransportationRate()
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Vehicle Rate Error '+data.status, detail: data.errorResponse, life: 6000});        
                        }
                    })
                }
            });
        }

        const cancelVehicleRateUpdate = () =>{
            vehicle_rate_form_type.value = 'add'
            initVehicleRateForm()
            v_vehiclerate$.value.$reset()
            isVehicleRateNull.value = false
            console.log(vehicle_rate_form)
        }

        const validateVehicleRate = () => {
            if(vehicle_rate_form.car_rate == null && vehicle_rate_form.van_rate == null && vehicle_rate_form.v25s_rate == null && vehicle_rate_form.v35s_rate == null && vehicle_rate_form.v45s_rate == null) {
                isVehicleRateNull.value = true
                return false
            } else {
                return true
            }
        }

        //Form Validations
        const vehiclerate_rules = computed(() => {
            return {
                desc: { required },
            }            
        })
        const v_vehiclerate$ = useVuelidate(vehiclerate_rules, vehicle_rate_form)
        const validateVehicleRateForm = () => {
            v_vehiclerate$.value.$validate();
            if(!v_vehiclerate$.value.$error){
                return true
            } else {
                return false
            }
        }

        return {
            companyname_header,

            transportationContractList,
            selectedTransportationContract,
            onTransportContractRowSelect,
            
            v_contractform$,
            transport_contract_form,
            contractform_type,
            addTransportationContract,
            updateTransportationContract,
            editTransportationContract,
            cancelContractUpdate,
            confirmDeleteTransportationContract,

            v_contractperiod$,
            transContractPeriodList,
            selectedTransContractPeriod,
            contractperiodform_type,
            trans_contractperiod_form,
            isDateValid,
            onTransContractPeriodRowSelect,
            addTransportationContractPeriod,
            updateTransportationContractPeriod,
            cancelContractPeriodUpdate,
            editTransContractPeriod,
            confirmDeleteTransContractPeriod,
            contractRateTitle,

            v_vehiclerate$,
            vehicleRateList,
            selectedVehicleRate ,
            onVehicleRateRowSelect,
            vehicle_rate_form_type,
            vehicle_rate_form,
            addVehicleRate,
            editVehicleRate,
            updateVehicleRate,
            cancelVehicleRateUpdate,
            confirmDeleteVehicleRate,
            isVehicleRateNull,
            vehicleRateButton,
        }
    },
}
</script>

<style lang="scss" scoped>
.contract-input-bar .p-inputtext{
    width: 49rem;
    margin-right: .8rem;
}
</style>